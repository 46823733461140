import { E_GlobalDomains, ManageDomains, RegionToManageDomains, USE_NEW_DOMAIN_PRACTICE_IDS } from "./constants";
import { isE2E, isSandbox } from "./utils";

export function useNewDomainOnStage(stage: string): boolean {
  return isSandbox(stage) || isE2E(stage);
}

export function useNewDomainInRegion(region: string): boolean {
  return region === "ca-central-1";
}

export function useNewDomain(practiceId: string, region: string, stage: string): boolean {
  if (useNewDomainOnStage(stage)) {
    return true;
  }

  if (useNewDomainInRegion(region)) {
    return true;
  }

  return USE_NEW_DOMAIN_PRACTICE_IDS.includes(practiceId);
}

function shouldUseStageSpecificDomain(stage: string): boolean {
  if (isE2E(stage) || isSandbox(stage)) {
    return true;
  }

  return stage === "test";
}

export function getNewDomain(domain: string, stage: string): string {
  if (domain.includes("dentr.net")) {
    return domain.replace("-patient.dentr.net", `.${E_GlobalDomains.PRODUCTION}`).replace("dentr.net", E_GlobalDomains.PRODUCTION);
  }

  if (domain.includes("dentr.io") && shouldUseStageSpecificDomain(stage)) {
    // Allow stages other than sandbox and e2e to continue using the old dentr.io domain until https://github.com/dentally/dentr/issues/5191 is completed
    return domain.replace("-patient.dentr.io", `.${E_GlobalDomains.SANDBOX}`).replace("sandbox.sandbox", "sandbox");
  }

  return domain;
}

export function getDomain(practiceId: string, domain: string, stage: string, region: string): string {
  if (!useNewDomain(practiceId, region, stage) || !domain) return domain;

  return getNewDomain(domain, stage);
}

export function getGlobalDomain(isProd: boolean): string {
  return isProd ? E_GlobalDomains.PRODUCTION : E_GlobalDomains.SANDBOX;
}

export function getShortCodeDomain(domain: string, isProd: boolean): string {
  if (!domain.endsWith(E_GlobalDomains.PRODUCTION) && !domain.endsWith("dentr.io") && !domain.endsWith("dentr.net")) {
    return domain;
  }

  return getGlobalDomain(isProd);
}

export function getManageDomains(region: string): (typeof ManageDomains)[keyof typeof ManageDomains] {
  return RegionToManageDomains[region];
}
